import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Link
  } from "react-router-dom";


import 'survey-core/modern.min.css';
// import 'survey-core/survey.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useEffect } from "react";
import axios from 'axios';

StylesManager.applyTheme("modern");


function Course() {

    const { id } = useParams()

    const [loading, setLoading] = useState(false);
    const [surveyJson, setSurveyJson] = useState([]);

    useEffect(() => {
        const loadQuestions = async () => {

            setLoading(true);

            const response = await axios.get(
                "https://axloekei84udj29sj1iscj3idj9.adrianolettieri.com/test/" + id);

            setSurveyJson(response.data);

            setLoading(false);
        }

        loadQuestions();
    }, []);

    const survey = new Model(surveyJson);
    survey.showProgressBar = "bottom";
    survey.showTimerPanel = "on";


    const correctStr = "Corretto";
    const inCorrectStr = "Sbagliato";

    function getTextHtml(text, str, isCorrect) {
        if (text.indexOf(str) < 0)
            return undefined;
        return text.substring(0, text.indexOf(str)) + "<span style='color:" + (
            isCorrect
                ? "green"
                : "red") + "'>" + str + "</span>";
    }
    function isAnswerCorrect(q) {
        debugger;
        const right = q.correctAnswer;
        if (!right || q.isEmpty())
            return undefined;
        var left = q.value;
        if (!Array.isArray(right))
            return right == left;
        if (!Array.isArray(left))
            left = [left];
        for (var i = 0; i < left.length; i++) {
            if (right.indexOf(left[i]) < 0)
                return false;
        }
        return true;
    }

    function renderCorrectAnswer(q) {
        if (!q)
            return;
        const isCorrect = isAnswerCorrect(q);
        if (!q.prevTitle) {
            q.prevTitle = q.title;
        }
        if (isCorrect === undefined) {
            q.title = q.prevTitle;
        }
        q.title = q.prevTitle + ' ' + (
            isCorrect
                ? correctStr
                : inCorrectStr);
    }

    survey
        .onValueChanged
        .add((sender, options) => {
            renderCorrectAnswer(options.question);
        });

    survey
        .onTextMarkdown
        .add((sender, options) => {
            var text = options.text;
            var html = getTextHtml(text, correctStr, true);
            if (!html) {
                html = getTextHtml(text, inCorrectStr, false);
            }
            if (!!html) {
                options.html = html;
            }
        });



    survey
        .onComplete
        .add(function (sender) {
            document
                .querySelector('#surveyResult')
                .textContent = "Result JSON:\n" + JSON.stringify(sender.data, null, 3);
            survey
                .getAllQuestions()
                .forEach(q => renderCorrectAnswer(q));
        });

    return (
        <>
            <div className="Course">
                {
                    loading ? (
                        <h4>Loading...</h4>) : <Survey model={survey} />
                }
            </div>
            <div> 
                <Link className="sv-btn sv-btn--navigation sv-footer__start-btn"
                    to={{
                        pathname: "/" 
                    }}
                > Torna alla selezione dei Corsi </Link>
                <Link className="sv-btn sv-btn--navigation sv-footer__start-btn"
                    to={{
                        pathname: "/course/" + id
                    }} 
                > Inizia un nuovo test </Link>
            </div>
        </>
    );  
}

export default Course;