    import {  useState } from 'react';


    import {useEffect} from "react";
    import axios from 'axios';
    import {
        Link
      } from "react-router-dom";

            
            
    function App() {

      const [loading, setLoading] = useState(false);
      const [surveyJson, setSurveyJson] = useState([]);

      useEffect(() => {
        const loadQuestions = async () => {

            setLoading(true);

            const response = await axios.get(
              "https://axloekei84udj29sj1iscj3idj9.adrianolettieri.com/test/");

            setSurveyJson(response.data);

            setLoading(false);
        }

        loadQuestions();
    }, []);

      return (
        <>
          <div className="App">
              { 
                loading ? (
                <h4>Loading...</h4>) : (

                    <ul>
                        {
                            surveyJson.map(function(survey){
                                return <li key={survey.id}><Link
                                to={{
                                  pathname: "/course/"  + survey.id
                                }}
                              > {survey.course} </Link>
                              </li>
                            //return <li> {survey.id} as the {survey.course} </li>
                            })
                        }
                    </ul>
                )
              }
          </div>
        </>
      );
    }

    export default App;